<template>
  <div class="ImCategories">
    <h2 class="subheading black--text ml-5">Transactions</h2>
    <h3
      class="mt-2 mt-5 ml-5 mb-3"
      style="color: black"
      v-if="transactions.length === 0"
    >
      Please import your data here
      <v-btn to="/upload" text color="black" style="font-size: medium;">
        <v-icon>mdi-attachment</v-icon>
      </v-btn>
    </h3>

    <v-container fluid>
      <v-card
        flat
        class="mb-4"
        v-for="transaction in transactions"
        :key="transaction.name"
        :style="`border-left: 4px solid yellowgreen;`"
      >
        <v-layout row wrap class="pa-3">
          <ImTransactionRow
            :bookingDate="transaction['bookingDate']"
            :rname="transaction['counterpartName']"
            :rvalue="transaction['amount']"
            :rcurrency="transaction['currency']"
          />
        </v-layout>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import ImTransactionRow from "@/components/transactions/ImTransactionRow.vue";
import TransactionsService from "@/services/TransactionsService";

export default {
  components: {
    ImTransactionRow
  },
  data() {
    return {
      transactions: []
    };
  },
  created() {
    TransactionsService.getAllTransactions()
      .then(response => {
        this.transactions = response.data;
        console.log(this.transactions);
      })
      .catch(error => {
        console.log("There was an error:", error.response);
      });
  },
  methods: {
    sortBy(prop) {
      this.projects.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
    }
  }
};
</script>

<style>
.project.complete {
  border-left: 4px solid #3cd1c2;
}
.project.ongoing {
  border-left: 4px solid #ffaa2c;
}
.project.overdue {
  border-left: 4px solid #f83e70;
}
.v-chip.complete {
  background: #3cd1c2;
}
.v-chip.ongoing {
  background: #ffaa2c;
}
.v-chip.overdue {
  background: #f83e70;
}
</style>
