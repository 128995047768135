<template>
  <div class="ImCategories" v-if="repetitions.length > 0">
    <a v-on:click="areRepetitionsHidden = !areRepetitionsHidden">
      <span>
        <h2 class="subheading black--text ml-5">
          Spending Repetitions
          <v-icon class="ml-3" v-if="!areRepetitionsHidden">mdi-eye</v-icon>
          <v-icon class="ml-3" v-if="areRepetitionsHidden">mdi-eye-off</v-icon>
        </h2>
      </span>
    </a>

    <v-container fluid>
      <div v-if="!areRepetitionsHidden">
        <ImSpendingsRepetitionCard
          v-for="repetition in repetitions"
          :key="repetition.name"
          :repetitionName="repetition.repetitionMonths.name"
          :repetitionSum="repetition.repetitionMonths.sum"
          :categoryName="repetition.categoryName"
          :january="repetition.repetitionMonths.jan"
          :february="repetition.repetitionMonths.feb"
          :march="repetition.repetitionMonths.mar"
          :april="repetition.repetitionMonths.apr"
          :mai="repetition.repetitionMonths.mai"
          :june="repetition.repetitionMonths.jun"
          :july="repetition.repetitionMonths.jul"
          :august="repetition.repetitionMonths.aug"
          :september="repetition.repetitionMonths.sep"
          :oktober="repetition.repetitionMonths.okt"
          :november="repetition.repetitionMonths.nov"
          :december="repetition.repetitionMonths.dec"
          :januaryTXs="repetition.repetitionMonths.janTXs"
          :februaryTXs="repetition.repetitionMonths.febTXs"
          :marchTXs="repetition.repetitionMonths.marTXs"
          :aprilTXs="repetition.repetitionMonths.aprTXs"
          :maiTXs="repetition.repetitionMonths.maiTXs"
          :juneTXs="repetition.repetitionMonths.junTXs"
          :julyTXs="repetition.repetitionMonths.julTXs"
          :augustTXs="repetition.repetitionMonths.augTXs"
          :septemberTXs="repetition.repetitionMonths.sepTXs"
          :oktoberTXs="repetition.repetitionMonths.oktTXs"
          :novemberTXs="repetition.repetitionMonths.novTXs"
          :decemberTXs="repetition.repetitionMonths.decTXs"
        />
      </div>
    </v-container>
  </div>
</template>

<script>
import ImSpendingsRepetitionCard from "@/components/spending/ImSpendingsRepetitionCard";
import SpendingsService from "@/services/SpendingsService";

export default {
  components: {
    ImSpendingsRepetitionCard
  },
  data() {
    return {
      repetitions: [],
      areRepetitionsHidden: false
    };
  },
  created() {
    SpendingsService.getSpendingRepetitions()
      .then(response => {
        this.repetitions = response.data;
        //console.log(this.repetitions);
      })
      .catch(error => {
        console.log("There was an error:", error.response);
      });
  },
  methods: {
    sortBy(prop) {
      this.projects.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
    }
  }
};
</script>
