import axios from "axios";

export default {
  getSpendingCategories() {
    return axios.get("https://api.investonom.com/api/v1/categories/spendings");
  },
  getSpendingRepetitions() {
    return axios.get("https://api.investonom.com/api/v1/repetitions/debtors");
  },
  getAllSpendings() {
    return axios.get("https://api.investonom.com/api/v1/counterparts/debtors");
  },
  getFixSpendings() {
    return axios.get("https://api.investonom.com/api/v1/counterparts/debtors/fix");
  },
  getFqfSpendings() {
    return axios.get("https://api.investonom.com/api/v1/counterparts/debtors/fqf");
  },
  getFqvSpendings() {
    return axios.get("https://api.investonom.com/api/v1/counterparts/debtors/fqv");
  },
  getVarSpendings() {
    return axios.get("https://api.investonom.com/api/v1/counterparts/debtors/var");
  },
  updateRepetitionCategory(repetitionParams) {
    return axios
      .post("https://api.investonom.com/api/v1/counterparts/changeRepetition", repetitionParams)
      .then(({ data }) => {
        console.log(data);
        location.reload();
      })
      .catch(function(error) {
        // TODO: do something with the error message
        console.log(error);
      });
  },
  updateCounterpartCategoryName(categoryParams) {
    return axios
      .post("https://api.investonom.com/api/v1/counterparts/changeCategory", categoryParams)
      .then(({ data }) => {
        console.log(data);
        location.reload();
      })
      .catch(function(error) {
        // TODO: do something with the error message
        console.log(error);
      });
  }
};
