<template>
  <div class="ImCategories">
    <h2 class="subheading black--text ml-5">Incomes</h2>
    <h3
      class="mt-2 mt-5 ml-5 mb-3"
      style="color: black"
      v-if="
        fixIncomes.length === 0 &&
          fqfIncomes.length === 0 &&
          fqvIncomes.length === 0 &&
          varIncomes.length === 0
      "
    >
      Please import your data here
      <v-btn to="/upload" text color="black" style="font-size: medium;">
        <v-icon>mdi-attachment</v-icon>
      </v-btn>
    </h3>

    <v-container fluid>
      <!-- FIX -->

      <a v-on:click="areFixIncomesHidden = !areFixIncomesHidden">
        <span>
          <h3
            class="mt-2 ml-2 mb-3"
            style="color: black"
            v-if="fixIncomes.length > 0"
          >
            Regular Incomes
            <v-icon class="ml-3" v-if="!areFixIncomesHidden">mdi-eye</v-icon>
            <v-icon class="ml-3" v-if="areFixIncomesHidden">mdi-eye-off</v-icon>
          </h3>
        </span>
      </a>

      <div v-if="!areFixIncomesHidden">
        <ImIncomesCounterpartsCard
          v-for="income in fixIncomes"
          :key="income.name"
          :counterpartName="income.counterpartMonths.name"
          :counterpartMultipointIdentifier="
            income.counterpartMultipointIdentifier
          "
          :counterpartSum="income.counterpartMonths.sum"
          :repetition="income.repetitionName"
          :repetitionName="income.repetitionName"
          :categoryName="income.categoryName"
          :january="income.counterpartMonths.jan"
          :february="income.counterpartMonths.feb"
          :march="income.counterpartMonths.mar"
          :april="income.counterpartMonths.apr"
          :mai="income.counterpartMonths.mai"
          :june="income.counterpartMonths.jun"
          :july="income.counterpartMonths.jul"
          :august="income.counterpartMonths.aug"
          :september="income.counterpartMonths.sep"
          :oktober="income.counterpartMonths.okt"
          :november="income.counterpartMonths.nov"
          :december="income.counterpartMonths.dec"
          :januaryTXs="income.counterpartMonths.janTXs"
          :februaryTXs="income.counterpartMonths.febTXs"
          :marchTXs="income.counterpartMonths.marTXs"
          :aprilTXs="income.counterpartMonths.aprTXs"
          :maiTXs="income.counterpartMonths.maiTXs"
          :juneTXs="income.counterpartMonths.junTXs"
          :julyTXs="income.counterpartMonths.julTXs"
          :augustTXs="income.counterpartMonths.augTXs"
          :septemberTXs="income.counterpartMonths.sepTXs"
          :oktoberTXs="income.counterpartMonths.oktTXs"
          :novemberTXs="income.counterpartMonths.novTXs"
          :decemberTXs="income.counterpartMonths.decTXs"
        />
      </div>

      <!-- FQF -->

      <a v-on:click="areFqfIncomesHidden = !areFqfIncomesHidden">
        <span>
          <h3
            class="mt-8 ml-2 mb-3"
            style="color: black"
            v-if="fqfIncomes.length > 0"
          >
            Periodical Incomes
            <v-icon class="ml-3" v-if="!areFqfIncomesHidden">mdi-eye</v-icon>
            <v-icon class="ml-3" v-if="areFqfIncomesHidden">mdi-eye-off</v-icon>
          </h3>
        </span>
      </a>

      <div v-if="!areFqfIncomesHidden">
        <ImIncomesCounterpartsCard
          v-for="income in fqfIncomes"
          :key="income.name"
          :counterpartName="income.counterpartMonths.name"
          :counterpartMultipointIdentifier="
            income.counterpartMultipointIdentifier
          "
          :counterpartSum="income.counterpartMonths.sum"
          :repetition="income.repetitionName"
          :repetitionName="income.repetitionName"
          :categoryName="income.categoryName"
          :january="income.counterpartMonths.jan"
          :february="income.counterpartMonths.feb"
          :march="income.counterpartMonths.mar"
          :april="income.counterpartMonths.apr"
          :mai="income.counterpartMonths.mai"
          :june="income.counterpartMonths.jun"
          :july="income.counterpartMonths.jul"
          :august="income.counterpartMonths.aug"
          :september="income.counterpartMonths.sep"
          :oktober="income.counterpartMonths.okt"
          :november="income.counterpartMonths.nov"
          :december="income.counterpartMonths.dec"
          :januaryTXs="income.counterpartMonths.janTXs"
          :februaryTXs="income.counterpartMonths.febTXs"
          :marchTXs="income.counterpartMonths.marTXs"
          :aprilTXs="income.counterpartMonths.aprTXs"
          :maiTXs="income.counterpartMonths.maiTXs"
          :juneTXs="income.counterpartMonths.junTXs"
          :julyTXs="income.counterpartMonths.julTXs"
          :augustTXs="income.counterpartMonths.augTXs"
          :septemberTXs="income.counterpartMonths.sepTXs"
          :oktoberTXs="income.counterpartMonths.oktTXs"
          :novemberTXs="income.counterpartMonths.novTXs"
          :decemberTXs="income.counterpartMonths.decTXs"
        />
      </div>

      <!-- FQV -->

      <a v-on:click="areFqvIncomesHidden = !areFqvIncomesHidden">
        <span>
          <h3
            class="mt-8 ml-2 mb-3"
            style="color: black"
            v-if="fqvIncomes.length > 0"
          >
            Regular Earnings
            <v-icon class="ml-3" v-if="!areFqvIncomesHidden">mdi-eye</v-icon>
            <v-icon class="ml-3" v-if="areFqvIncomesHidden">mdi-eye-off</v-icon>
          </h3>
        </span>
      </a>

      <div v-if="!areFqvIncomesHidden">
        <ImIncomesCounterpartsCard
          v-for="income in fqvIncomes"
          :key="income.name"
          :counterpartName="income.counterpartMonths.name"
          :counterpartMultipointIdentifier="
            income.counterpartMultipointIdentifier
          "
          :counterpartSum="income.counterpartMonths.sum"
          :repetition="income.repetitionName"
          :repetitionName="income.repetitionName"
          :categoryName="income.categoryName"
          :january="income.counterpartMonths.jan"
          :february="income.counterpartMonths.feb"
          :march="income.counterpartMonths.mar"
          :april="income.counterpartMonths.apr"
          :mai="income.counterpartMonths.mai"
          :june="income.counterpartMonths.jun"
          :july="income.counterpartMonths.jul"
          :august="income.counterpartMonths.aug"
          :september="income.counterpartMonths.sep"
          :oktober="income.counterpartMonths.okt"
          :november="income.counterpartMonths.nov"
          :december="income.counterpartMonths.dec"
          :januaryTXs="income.counterpartMonths.janTXs"
          :februaryTXs="income.counterpartMonths.febTXs"
          :marchTXs="income.counterpartMonths.marTXs"
          :aprilTXs="income.counterpartMonths.aprTXs"
          :maiTXs="income.counterpartMonths.maiTXs"
          :juneTXs="income.counterpartMonths.junTXs"
          :julyTXs="income.counterpartMonths.julTXs"
          :augustTXs="income.counterpartMonths.augTXs"
          :septemberTXs="income.counterpartMonths.sepTXs"
          :oktoberTXs="income.counterpartMonths.oktTXs"
          :novemberTXs="income.counterpartMonths.novTXs"
          :decemberTXs="income.counterpartMonths.decTXs"
        />
      </div>

      <!-- VAR -->

      <a v-on:click="areVarIncomesHidden = !areVarIncomesHidden">
        <span>
          <h3
            class="mt-8 ml-2 mb-3"
            style="color: black"
            v-if="varIncomes.length > 0"
          >
            Occasional Earnings
            <v-icon class="ml-3" v-if="!areVarIncomesHidden">mdi-eye</v-icon>
            <v-icon class="ml-3" v-if="areVarIncomesHidden">mdi-eye-off</v-icon>
          </h3>
        </span>
      </a>

      <div v-if="!areVarIncomesHidden">
        <ImIncomesCounterpartsCard
          v-for="income in varIncomes"
          :key="income.name"
          :counterpartName="income.counterpartMonths.name"
          :counterpartMultipointIdentifier="
            income.counterpartMultipointIdentifier
          "
          :counterpartSum="income.counterpartMonths.sum"
          :repetition="income.repetitionName"
          :repetitionName="income.repetitionName"
          :categoryName="income.categoryName"
          :january="income.counterpartMonths.jan"
          :february="income.counterpartMonths.feb"
          :march="income.counterpartMonths.mar"
          :april="income.counterpartMonths.apr"
          :mai="income.counterpartMonths.mai"
          :june="income.counterpartMonths.jun"
          :july="income.counterpartMonths.jul"
          :august="income.counterpartMonths.aug"
          :september="income.counterpartMonths.sep"
          :oktober="income.counterpartMonths.okt"
          :november="income.counterpartMonths.nov"
          :december="income.counterpartMonths.dec"
          :januaryTXs="income.counterpartMonths.janTXs"
          :februaryTXs="income.counterpartMonths.febTXs"
          :marchTXs="income.counterpartMonths.marTXs"
          :aprilTXs="income.counterpartMonths.aprTXs"
          :maiTXs="income.counterpartMonths.maiTXs"
          :juneTXs="income.counterpartMonths.junTXs"
          :julyTXs="income.counterpartMonths.julTXs"
          :augustTXs="income.counterpartMonths.augTXs"
          :septemberTXs="income.counterpartMonths.sepTXs"
          :oktoberTXs="income.counterpartMonths.oktTXs"
          :novemberTXs="income.counterpartMonths.novTXs"
          :decemberTXs="income.counterpartMonths.decTXs"
        />
      </div>
    </v-container>
  </div>
</template>

<script>
import ImIncomesCounterpartsCard from "@/components/income/ImIncomesCounterpartsCard.vue";
import IncomesService from "@/services/IncomesService";

export default {
  components: {
    ImIncomesCounterpartsCard
  },
  data() {
    return {
      repetitionOptionSelected: String,
      repetitionOptions: [
        "Regular Incomes",
        "Periodical Incomes",
        "Regular Earnings",
        "Occasional Earnings"
      ],

      fixIncomes: [],
      areFixIncomesHidden: false,

      fqfIncomes: [],
      areFqfIncomesHidden: false,

      fqvIncomes: [],
      areFqvIncomesHidden: false,

      varIncomes: [],
      areVarIncomesHidden: true
    };
  },
  created() {
    IncomesService.getFixIncomes()
      .then(response => {
        this.fixIncomes = response.data;
        //console.log(this.categoryMonths);
      })
      .catch(error => {
        console.log("There was an error:", error.response);
      });

    IncomesService.getFqfIncomes()
      .then(response => {
        this.fqfIncomes = response.data;
        //console.log(this.categoryMonths);
      })
      .catch(error => {
        console.log("There was an error:", error.response);
      });

    IncomesService.getFqvIncomes()
      .then(response => {
        this.fqvIncomes = response.data;
        //console.log(this.categoryMonths);
      })
      .catch(error => {
        console.log("There was an error:", error.response);
      });

    IncomesService.getVarIncomes()
      .then(response => {
        this.varIncomes = response.data;
        //console.log(this.categoryMonths);
      })
      .catch(error => {
        console.log("There was an error:", error.response);
      });
  },
  methods: {
    sortBy(prop) {
      this.projects.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
    },
    selectingRepetition(event) {
      console.log(event.target.value);
      this.repetitionOptionSelected = event.target.value;
    }
  }
};
</script>

<style>
.project.complete {
  border-left: 4px solid #3cd1c2;
}
.project.ongoing {
  border-left: 4px solid #ffaa2c;
}
.project.overdue {
  border-left: 4px solid #f83e70;
}
.v-chip.complete {
  background: #3cd1c2;
}
.v-chip.ongoing {
  background: #ffaa2c;
}
.v-chip.overdue {
  background: #f83e70;
}
</style>
