<template>
  <v-flex xs4 md1 class="order-0 align-content-center">
    <div class="caption ml-3 font-weight-medium">{{ cname }}</div>
    <!--div class="ml-3 im-font-size" style="font-weight: normal">
      <span v-bind="attrs" v-on="on">{{ cvalue }}</span>
    </div-->

    <v-dialog v-model="dialog" width="450">
      <template v-slot:activator="{ on, attrs }">
        <div class="ml-3 im-font-size" style="font-weight: normal">
          <span v-if="txIdList === null">{{ cvalue }}</span>
          <span
            v-if="txIdList != null"
            v-bind="attrs"
            v-on="on"
            @click="openTransactionsList"
            >{{ formatPrice(cvalue) }}</span
          >
        </div>
      </template>

      <v-card style="background-color: #F5F5F5">
        <v-card-title class="headline">
          {{ ctitle }}
        </v-card-title>

        <v-card-subtitle class="mt-0" style="font-size: medium; color: black;">
          <span style="font-size: medium; font-weight: normal;">{{
            cname
          }}</span
          >:
          <span>{{ formatPrice(cvalue) }} EUR</span>
        </v-card-subtitle>

        <v-card-text>
          <v-card
            flat
            class="mb-4"
            v-for="transaction in transactions"
            :key="transaction.name"
            :style="`border-left: 4px solid yellowgreen;`"
          >
            <v-layout row wrap class="pa-3">
              <ImTransactionRow
                :valueYear="transaction['valueYear']"
                :valueMonth="transaction['valueMonth']"
                :valueDay="transaction['valueDay']"
                :bookingYear="transaction['bookingYear']"
                :bookingMonth="transaction['bookingMonth']"
                :bookingDay="transaction['bookingDay']"
                :cdtDbtInd="transaction['cdtDbtInd']"
                :bookingDate="transaction['bookingDate']"
                :valueDate="transaction['valueDate']"
                :amount="transaction['amount']"
                :currency="transaction['currency']"
                :status="transaction['status']"
                :counterpartName="transaction['counterpartName']"
                :purpose="transaction['purpose']"
                :type="transaction['type']"
              />
            </v-layout>
          </v-card>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center">
          <v-btn
            style="align-self: center"
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import ImTransactionRow from "@/components/spending/ImTransactionRow";
import TransactionsService from "@/services/TransactionsService";

export default {
  components: {
    ImTransactionRow
  },
  data() {
    return {
      dialog: false,
      transactions: []
    };
  },
  props: {
    ctitle: String,
    cname: String,
    cvalue: Number,
    txIdList: String
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    openTransactionsList: function() {
      //console.log("openTransactionsList: " + this.txIdList);

      TransactionsService.getTXsWithIds(this.txIdList)
        .then(response => {
          this.transactions = response.data;
          //console.log(this.transactions);
        })
        .catch(error => {
          console.log("There was an error:", error.response);
        });
    }
  }
};
</script>

<style>
@media (min-width: 340px) {
  .im-font-size {
    font-size: larger;
    margin-bottom: 15px;
  }
}

@media (min-width: 540px) {
  .im-font-size {
    font-size: larger;
    margin-bottom: 15px;
  }
}

@media (min-width: 800px) {
  .im-font-size {
    font-size: large;
  }
}

@media (min-width: 1280px) {
  .im-font-size {
    font-size: x-large;
  }
}
</style>
