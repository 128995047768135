<template>
  <v-flex xs12 md12 class="order-0 align-content-center">
    <div class="ml-3" style="font-size: larger; font-weight: normal;">
      <v-layout row wrap class="pl-3 pb-2 pt-3">
        <span style="color: black; font-weight: normal; font-size: small;">
          {{ bookingDate }}
        </span>
      </v-layout>

      <v-layout row wrap class="pa-3">
        <span style="color: black; font-size: large; font-weight: bold">
          {{ rname }}
        </span>
      </v-layout>

      <v-layout row wrap class="pl-3 pb-3">
        <span class="mt-2" style="color: black; font-size: medium;">
          <span class="mr-1">{{ formatPrice(rvalue) }} {{ rcurrency }}</span>
        </span>
      </v-layout>
    </div>
  </v-flex>
</template>

<script>
export default {
  props: {
    bookingDate: String,
    rname: String,
    rvalue: Number,
    rcurrency: String
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  }
};
</script>
