<template>
  <v-app class="grey lighten-4" style="background: #F5F5F5">
    <ImNavbar />

    <v-container fluid>
      <ImStaticBalance />
      <!--ImDynamicBalance /-->
    </v-container>
  </v-app>
</template>

<script>
import ImNavbar from "@/components/ImNavbar";
import ImStaticBalance from "@/components/balances/ImStaticBalance";
//import ImDynamicBalance from "@/components/balances/ImDynamicBalance";

export default {
  components: { ImNavbar, ImStaticBalance }
};
</script>
