<template>
  <v-app class="grey lighten-4" style="background: #F5F5F5">
    <ImNavbar />

    <v-container fluid>
      <div class="pb-10">
        <h2 class="subheading black--text ml-5 mb-10">
          Import new bank connection
        </h2>

        <h3 class="subheading black--text ml-5 mb-10">Search banks</h3>

        <div class="mx-5">
          <v-form class="pb-10">
            <v-text-field
              label="Bank name"
              type="text"
              v-model="bankName"
              required
            ></v-text-field>
          </v-form>

          <v-btn
            type="submit"
            name="button"
            color="primary"
            class="mr-4"
            @click="searchBanks()"
            >Search
          </v-btn>
        </div>
      </div>

      <span
        v-if="banks.length > 0"
        class="ml-3"
        style="font-size: larger; font-weight: bold;"
        >Please select your bank</span
      >

      <div class="ml-2 mt-10">
        <v-card
          flat
          class="mb-4"
          v-for="bank in banks"
          :key="bank.id"
          :style="`border-left: 4px solid yellowgreen;`"
        >
          <v-layout row wrap class="pa-3">
            <ImBanksRow
              :bankId="bank['id']"
              :bankName="bank['name']"
              :bankBic="bank['bic']"
              :bankBlz="bank['blz']"
              :bankLocation="bank['location']"
              :bankCity="bank['city']"
              :bankInterface="bank['bankInterface']"
            />
          </v-layout>
        </v-card>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import ImNavbar from "@/components/ImNavbarUserSettings";
import BankService from "@/services/BankService";
import ImBanksRow from "@/components/banks/ImBanksRow";

export default {
  components: { ImNavbar, ImBanksRow },
  data() {
    return {
      banks: [],
      bankName: "",
      errors: null
    };
  },
  methods: {
    searchBanks() {
      BankService.searchBanks(this.bankName)
        .then(response => {
          this.banks = response.data;
          //console.log(this.banks);
        })
        .catch(error => {
          console.log("There was an error:", error.response);
        });
    }
  }
};
</script>
