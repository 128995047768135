import axios from "axios";

export default {
  searchBanks(bankName) {
    let axiosInstance = axios.create({
      baseURL: `https://api.investonom.com/api/v1`,
      withCredentials: false, // This is the default
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //Authorization: "IJhrtQfeNZZe7wouJB4znig20pfXygwiEEwjUHDkioZZVuCfwV6ZF0jeKW95vkTqzYX4gHJQjq60hMjkrCwum6gDttk54vda7HW2"
        Authorization: "secret"
        //User: user
      }
    });

    const params = "{" + '"bankName" : "' + bankName + '"}';
    return axiosInstance.post("/bank/search", params);
  },
  connectBank(bankId, bankInterface) {
    let axiosInstance = axios.create({
      baseURL: `https://api.investonom.com/api/v1`,
      withCredentials: false, // This is the default
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //Authorization: "IJhrtQfeNZZe7wouJB4znig20pfXygwiEEwjUHDkioZZVuCfwV6ZF0jeKW95vkTqzYX4gHJQjq60hMjkrCwum6gDttk54vda7HW2"
        Authorization: "secret"
        //User: user
      }
    });

    const params =
      "{" +
      '"bankId" : ' +
      bankId +
      ", " +
      '"bankInterface" : "' +
      bankInterface +
      '"}';
    return axiosInstance.post("/bank/connectBank", params);
  },
  importStatus(bankId, bankInterface) {
    let axiosInstance = axios.create({
      baseURL: `https://api.investonom.com/api/v1`,
      withCredentials: false, // This is the default
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //Authorization: "IJhrtQfeNZZe7wouJB4znig20pfXygwiEEwjUHDkioZZVuCfwV6ZF0jeKW95vkTqzYX4gHJQjq60hMjkrCwum6gDttk54vda7HW2"
        Authorization: "secret"
        //User: user
      }
    });

    const params =
      "{" +
      '"bankId" : ' +
      bankId +
      ", " +
      '"bankInterface" : "' +
      bankInterface +
      '"}';
    return axiosInstance.post("/bank/importStatus", params);
  },
  syncBankingData(bankId, bankInterface) {
    let axiosInstance = axios.create({
      baseURL: `https://api.investonom.com/api/v1`,
      withCredentials: false, // This is the default
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //Authorization: "IJhrtQfeNZZe7wouJB4znig20pfXygwiEEwjUHDkioZZVuCfwV6ZF0jeKW95vkTqzYX4gHJQjq60hMjkrCwum6gDttk54vda7HW2"
        Authorization: "secret"
        //User: user
      }
    });

    const params =
      "{" +
      '"bankId" : ' +
      bankId +
      ", " +
      '"bankInterface" : "' +
      bankInterface +
      '"}';
    return axiosInstance.post("/bank/syncBankingData", params);
  }
};
