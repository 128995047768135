import axios from "axios";

export default {
  getStaticBalances() {
    return axios.get("https://api.investonom.com/api/v1/balances/static");
  },
  getDynamicBalances() {
    return axios.get("https://api.investonom.com/api/v1/balances/dynamic");
  },
  getSomething(id) {
    return axios.get("https://api.investonom.com/api/v1/events/" + id);
  }
};
