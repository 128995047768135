<template>
  <v-card
    flat
    class="mb-4"
    :style="`border-left: 4px solid yellowgreen; background: #ffffff;`"
  >
    <a v-on:click="areCategoriesHidden = !areCategoriesHidden">
      <v-card-subtitle>
        <span style="color: black; font-weight: bold; font-size: larger;">
          {{ categoriesName }}</span
        >
      </v-card-subtitle>
    </a>

    <v-layout row wrap class="pa-1" v-if="areCategoriesHidden">
      <span class="ml-6 mb-1">{{ formatPrice(categoriesSum) }} EUR </span>
    </v-layout>

    <v-layout row wrap class="pt-1 ml-1 mb-3" v-if="!areCategoriesHidden">
      <span class="ml-3">{{ formatPrice(categoriesSum) }} EUR </span>
    </v-layout>

    <v-layout row wrap class="mt-5 ml-1" v-if="!areCategoriesHidden">
      <ImSpendingMonthColumn
        :ctitle="categoriesName"
        cname="January"
        :cvalue="january"
        :txIdList="januaryTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="categoriesName"
        cname="February"
        :cvalue="february"
        :txIdList="februaryTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="categoriesName"
        cname="March"
        :cvalue="march"
        :txIdList="marchTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="categoriesName"
        cname="April"
        :cvalue="april"
        :txIdList="aprilTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="categoriesName"
        cname="Mai"
        :cvalue="mai"
        :txIdList="maiTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="categoriesName"
        cname="June"
        :cvalue="june"
        :txIdList="juneTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="categoriesName"
        cname="July"
        :cvalue="july"
        :txIdList="julyTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="categoriesName"
        cname="August"
        :cvalue="august"
        :txIdList="augustTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="categoriesName"
        cname="September"
        :cvalue="september"
        :txIdList="septemberTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="categoriesName"
        cname="Oktober"
        :cvalue="oktober"
        :txIdList="oktoberTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="categoriesName"
        cname="November"
        :cvalue="november"
        :txIdList="novemberTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="categoriesName"
        cname="December"
        :cvalue="december"
        :txIdList="decemberTXs"
      />
    </v-layout>
  </v-card>
</template>

<script>
import ImSpendingMonthColumn from "@/components/spending/ImSpendingMonthColumn.vue";

export default {
  components: {
    ImSpendingMonthColumn
  },
  props: {
    categoriesName: String,
    categoriesSum: Number,

    january: Number,
    february: Number,
    march: Number,

    april: Number,
    mai: Number,
    june: Number,

    july: Number,
    august: Number,
    september: Number,

    oktober: Number,
    november: Number,
    december: Number,

    januaryTXs: String,
    februaryTXs: String,
    marchTXs: String,

    aprilTXs: String,
    maiTXs: String,
    juneTXs: String,

    julyTXs: String,
    augustTXs: String,
    septemberTXs: String,

    oktoberTXs: String,
    novemberTXs: String,
    decemberTXs: String
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  },
  data() {
    return {
      areCategoriesHidden: true
    };
  }
};
</script>

<style>
.project.complete {
  border-left: 4px solid #3cd1c2;
}
.project.ongoing {
  border-left: 4px solid #ffaa2c;
}
.project.overdue {
  border-left: 4px solid #f83e70;
}
.v-chip.complete {
  background: #3cd1c2;
}
.v-chip.ongoing {
  background: #ffaa2c;
}
.v-chip.overdue {
  background: #f83e70;
}
</style>
