<template>
  <v-flex xs12 md12 class="order-0 align-content-center">
    <div
      class="ml-3"
      style="font-size: larger; font-weight: normal;"
      v-on:click="areTransactionDetailsHidden = !areTransactionDetailsHidden"
    >
      <v-layout
        row
        wrap
        class="pl-3 pb-2 pt-3"
        v-if="areTransactionDetailsHidden"
      >
        <span style="color: black; font-weight: normal; font-size: small;">
          {{ bookingDay }}.{{ bookingMonth }}.{{ bookingYear }}
        </span>
      </v-layout>

      <v-layout row wrap class="pa-3" v-if="areTransactionDetailsHidden">
        <span style="color: black; font-size: large; font-weight: bold">
          {{ counterpartName }}
        </span>
      </v-layout>

      <v-layout row wrap class="pl-3 pb-3" v-if="areTransactionDetailsHidden">
        <span class="mt-2" style="color: black; font-size: large;">
          {{ formatPrice(amount) }} {{ currency }}
        </span>
      </v-layout>
    </div>

    <div
      class="mt-3 ml-3"
      style="font-size: larger; font-weight: normal;"
      v-on:click="areTransactionDetailsHidden = !areTransactionDetailsHidden"
      v-if="!areTransactionDetailsHidden"
    >
      <v-layout row wrap class="pa-3 pt-0">
        <span style="color: black; font-size: medium; font-weight: normal">
          {{ bookingDay }}.{{ bookingMonth }}.{{ bookingYear }}
        </span>
      </v-layout>

      <v-layout row wrap class="pa-3">
        <span style="color: black; font-size: larger; font-weight: bold">
          {{ counterpartName }}
        </span>
      </v-layout>

      <v-layout row wrap class="pa-3">
        <span
          class="ml-0"
          style="color: black; font-size: medium; font-weight: normal"
        >
          {{ type }}
        </span>
      </v-layout>

      <v-layout row wrap class="pa-3 pt-2">
        <span style="color: grey; font-size: small;">
          Value:
        </span>
      </v-layout>
      <v-layout row wrap class="pa-3 pt-0">
        <span
          class="ml-0"
          style="color: black; font-size: large; font-weight: bold"
        >
          {{ amount }} {{ currency }}
        </span>
      </v-layout>

      <v-layout row wrap class="pa-3" v-if="purpose.length > 0">
        <span style="color: grey; font-size: small;">
          Purpose:
        </span>
      </v-layout>
      <v-layout row wrap class="pa-3 pt-0">
        <span style="color: black; font-size: medium; font-weight: normal">
          {{ purpose }}
        </span>
      </v-layout>

      <!--v-layout row wrap class="pa-3">
        Status:
        <span style="color: black; font-size: medium; font-weight: bold">
          {{ status }} vagy Kategoria vagy Repetition stb stb
        </span>
      </v-layout-->
    </div>
  </v-flex>
</template>

<script>
export default {
  props: {
    valueYear: String,
    valueMonth: String,
    valueDay: String,
    bookingYear: String,
    bookingMonth: String,
    bookingDay: String,
    cdtDbtInd: String,
    bookingDate: String,
    valueDate: String,
    amount: String,
    currency: String,
    status: String,
    counterpartName: String,
    purpose: String,
    type: String
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  },
  data() {
    return {
      areTransactionDetailsHidden: true
    };
  }
};
</script>
