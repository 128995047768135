<template>
  <div class="ImStaticBalance">
    <h2 class="subheading black--text ml-5">Static Balance</h2>

    <h3
      class="mt-2 mt-5 ml-5 mb-3"
      style="color: black"
      v-if="Object.keys(allIncomes).length === 0"
    >
      Please import your data here
      <v-btn to="/upload" text color="black" style="font-size: medium;">
        <v-icon>mdi-attachment</v-icon>
      </v-btn>
    </h3>

    <v-container fluid v-if="Object.keys(allIncomes).length !== 0">
      <v-card flat class="mb-4" :style="`border-left: 4px solid yellowgreen;`">
        <v-card-subtitle>
          <span style="color: black; font-weight: bold; font-size: larger;">
            All Incomes
          </span>
          <span style="color: black; font-size: larger;"
            >: {{ allIncomes["sum"] }} EUR</span
          >
        </v-card-subtitle>
        <v-layout row wrap class="pa-3">
          <ImMonthColumn cname="January" :cvalue="allIncomes['jan']" />
          <ImMonthColumn cname="February" :cvalue="allIncomes['feb']" />
          <ImMonthColumn cname="March" :cvalue="allIncomes['mar']" />
          <ImMonthColumn cname="April" :cvalue="allIncomes['apr']" />
          <ImMonthColumn cname="Mai" :cvalue="allIncomes['mai']" />
          <ImMonthColumn cname="June" :cvalue="allIncomes['jun']" />
          <ImMonthColumn cname="July" :cvalue="allIncomes['jul']" />
          <ImMonthColumn cname="August" :cvalue="allIncomes['aug']" />
          <ImMonthColumn cname="September" :cvalue="allIncomes['sep']" />
          <ImMonthColumn cname="Oktober" :cvalue="allIncomes['okt']" />
          <ImMonthColumn cname="November" :cvalue="allIncomes['nov']" />
          <ImMonthColumn cname="December" :cvalue="allIncomes['dec']" />
        </v-layout>
      </v-card>

      <v-card flat class="mb-4" :style="`border-left: 4px solid yellowgreen;`">
        <v-card-subtitle>
          <span style="color: black; font-weight: bold; font-size: larger;">
            All Spendings
          </span>
          <span style="color: black; font-size: larger;"
            >: {{ allSpendings["sum"] }} EUR</span
          >
        </v-card-subtitle>
        <v-layout row wrap class="pa-3">
          <ImMonthColumn cname="January" :cvalue="allSpendings['jan']" />
          <ImMonthColumn cname="February" :cvalue="allSpendings['feb']" />
          <ImMonthColumn cname="March" :cvalue="allSpendings['mar']" />
          <ImMonthColumn cname="April" :cvalue="allSpendings['apr']" />
          <ImMonthColumn cname="Mai" :cvalue="allSpendings['mai']" />
          <ImMonthColumn cname="June" :cvalue="allSpendings['jun']" />
          <ImMonthColumn cname="July" :cvalue="allSpendings['jul']" />
          <ImMonthColumn cname="August" :cvalue="allSpendings['aug']" />
          <ImMonthColumn cname="September" :cvalue="allSpendings['sep']" />
          <ImMonthColumn cname="Oktober" :cvalue="allSpendings['okt']" />
          <ImMonthColumn cname="November" :cvalue="allSpendings['nov']" />
          <ImMonthColumn cname="December" :cvalue="allSpendings['dec']" />
        </v-layout>
      </v-card>

      <v-card flat class="mb-4" :style="`border-left: 4px solid yellowgreen;`">
        <v-card-subtitle>
          <span style="color: black; font-weight: bold; font-size: larger;">
            Difference
          </span>
          <span style="color: black; font-size: larger;"
            >: {{ difference["sum"] }} EUR</span
          >
        </v-card-subtitle>
        <v-layout row wrap class="pa-3">
          <ImMonthColumn cname="January" :cvalue="difference['jan']" />
          <ImMonthColumn cname="February" :cvalue="difference['feb']" />
          <ImMonthColumn cname="March" :cvalue="difference['mar']" />
          <ImMonthColumn cname="April" :cvalue="difference['apr']" />
          <ImMonthColumn cname="Mai" :cvalue="difference['mai']" />
          <ImMonthColumn cname="June" :cvalue="difference['jun']" />
          <ImMonthColumn cname="July" :cvalue="difference['jul']" />
          <ImMonthColumn cname="August" :cvalue="difference['aug']" />
          <ImMonthColumn cname="September" :cvalue="difference['sep']" />
          <ImMonthColumn cname="Oktober" :cvalue="difference['okt']" />
          <ImMonthColumn cname="November" :cvalue="difference['nov']" />
          <ImMonthColumn cname="December" :cvalue="difference['dec']" />
        </v-layout>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import ImMonthColumn from "@/components/balances/ImMonthColumn.vue";
import BalanceService from "@/services/BalanceService";

export default {
  components: {
    ImMonthColumn
  },
  data() {
    return {
      balances: [],
      allIncomes: Object,
      allSpendings: Object,
      difference: Object
    };
  },
  created() {
    BalanceService.getStaticBalances()
      .then(response => {
        this.balances = response.data[0];
        this.allIncomes = this.balances["allIncomes"];
        this.allSpendings = this.balances["allSpendings"];
        this.difference = this.balances["difference"];
        //console.log(response.data['allIncomes']['jan']);
      })
      .catch(error => {
        console.log("There was an error:", error.response);
      });
  },
  methods: {
    sortBy(prop) {
      this.projects.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
    }
  }
};
</script>

<style>
.project.complete {
  border-left: 4px solid #3cd1c2;
}
.project.ongoing {
  border-left: 4px solid #ffaa2c;
}
.project.overdue {
  border-left: 4px solid #f83e70;
}
.v-chip.complete {
  background: #3cd1c2;
}
.v-chip.ongoing {
  background: #ffaa2c;
}
.v-chip.overdue {
  background: #f83e70;
}
</style>
