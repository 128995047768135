<template>
  <v-app class="grey lighten-4" style="background: #F5F5F5">
    <ImNavbar />

    <v-container fluid>
      <div class="ImUserSettings">
        <h2 class="subheading black--text ml-5 mb-10">File Upload</h2>
        <Upload />
      </div>
    </v-container>
  </v-app>
</template>

<script>
import ImNavbar from "@/components/ImNavbarUserSettings";
import Upload from "@/components/upload/Upload";
export default {
  components: {
    ImNavbar,
    Upload
  }
};
</script>
