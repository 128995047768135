<template>
  <nav>
    <v-app-bar flat app style="background: white;">
      <v-toolbar-title class="light-green--text text-sm-h5">
        <v-btn text to="/" color="light-green">
          <span class="font-weight-black">investo</span>
          <span class="font-weight-regular">nom</span>
        </v-btn>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        to="/register"
        text
        color="light-green"
        class="text-sm-h6 text-lowercase"
      >
        <span>Register</span>
      </v-btn>

      <v-btn
        to="/login"
        text
        color="light-green"
        class="text-sm-h6 text-lowercase"
      >
        <span>Login</span>
      </v-btn>
    </v-app-bar>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      links: [
        { icon: "dashboard", text: "Dashboard", route: "/" },
        { icon: "folder", text: "My Projects", route: "/projects" },
        { icon: "person", text: "Team", route: "/team" }
      ]
    };
  }
};
</script>
