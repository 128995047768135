<template>
  <v-flex xs4 md1 class="order-0 align-content-center">
    <div class="caption ml-3 font-weight-medium">{{ cname }}</div>
    <div class="ml-3 im-font-size" style="font-weight: normal;">
      {{ formatPrice(cvalue) }}
    </div>
  </v-flex>
</template>

<script>
export default {
  props: {
    cname: String,
    cvalue: Number
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  }
};
</script>

<style>
@media (min-width: 340px) {
  .im-font-size {
    font-size: larger;
    margin-bottom: 15px;
  }
}

@media (min-width: 540px) {
  .im-font-size {
    font-size: larger;
    margin-bottom: 15px;
  }
}

@media (min-width: 800px) {
  .im-font-size {
    font-size: large;
  }
}

@media (min-width: 1280px) {
  .im-font-size {
    font-size: x-large;
  }
}
</style>
