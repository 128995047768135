<template>
  <v-app class="grey lighten-4" style="background: #F5F5F5;">
    <ImNavbar />

    <v-container fluid>
      <ImSpendingRepetitions />
      <ImSpendingCategories />
      <ImSpending />
    </v-container>
  </v-app>
</template>

<script>
import ImNavbar from "@/components/ImNavbar";
import ImSpendingCategories from "@/components/spending/ImSpendingCategories";
import ImSpendingRepetitions from "@/components/spending/ImSpendingRepetitions";
import ImSpending from "@/components/spending/ImSpendings";
export default {
  components: {
    ImNavbar,
    ImSpendingCategories,
    ImSpendingRepetitions,
    ImSpending
  }
};
</script>
