<template>
  <div class="ImCategories">
    <h2 class="subheading black--text ml-5">Spendings</h2>
    <h3
      class="mt-2 mt-5 ml-5 mb-3"
      style="color: black"
      v-if="
        fixSpendings.length === 0 &&
          fqfSpendings.length === 0 &&
          fqvSpendings.length === 0 &&
          varSpendings.length === 0
      "
    >
      Please import your data here
      <v-btn to="/upload" text color="black" style="font-size: medium;">
        <v-icon>mdi-attachment</v-icon>
      </v-btn>
    </h3>

    <v-container fluid>
      <!-- FIX -->

      <a v-on:click="areFixSpendingsHidden = !areFixSpendingsHidden">
        <span>
          <h3
            class="mt-2 ml-2 mb-3"
            style="color: black"
            v-if="fixSpendings.length > 0"
          >
            Regular Bills
            <v-icon class="ml-3" v-if="!areFixSpendingsHidden">mdi-eye</v-icon>
            <v-icon class="ml-3" v-if="areFixSpendingsHidden"
              >mdi-eye-off</v-icon
            >
          </h3>
        </span>
      </a>

      <div v-if="!areFixSpendingsHidden">
        <ImSpendingsCounterpartsCard
          v-for="spending in fixSpendings"
          :key="spending.name"
          :counterpartName="spending.counterpartMonths.name"
          :counterpartMultipointIdentifier="
            spending.counterpartMultipointIdentifier
          "
          :counterpartSum="spending.counterpartMonths.sum"
          :repetition="spending.repetitionName"
          :repetitionName="spending.repetitionName"
          :categoryName="spending.categoryName"
          :january="spending.counterpartMonths.jan"
          :february="spending.counterpartMonths.feb"
          :march="spending.counterpartMonths.mar"
          :april="spending.counterpartMonths.apr"
          :mai="spending.counterpartMonths.mai"
          :june="spending.counterpartMonths.jun"
          :july="spending.counterpartMonths.jul"
          :august="spending.counterpartMonths.aug"
          :september="spending.counterpartMonths.sep"
          :oktober="spending.counterpartMonths.okt"
          :november="spending.counterpartMonths.nov"
          :december="spending.counterpartMonths.dec"
          :januaryTXs="spending.counterpartMonths.janTXs"
          :februaryTXs="spending.counterpartMonths.febTXs"
          :marchTXs="spending.counterpartMonths.marTXs"
          :aprilTXs="spending.counterpartMonths.aprTXs"
          :maiTXs="spending.counterpartMonths.maiTXs"
          :juneTXs="spending.counterpartMonths.junTXs"
          :julyTXs="spending.counterpartMonths.julTXs"
          :augustTXs="spending.counterpartMonths.augTXs"
          :septemberTXs="spending.counterpartMonths.sepTXs"
          :oktoberTXs="spending.counterpartMonths.oktTXs"
          :novemberTXs="spending.counterpartMonths.novTXs"
          :decemberTXs="spending.counterpartMonths.decTXs"
        />
      </div>

      <!-- FREQUENT FIX -->

      <a v-on:click="areFqfSpendingsHidden = !areFqfSpendingsHidden">
        <span>
          <h3
            class="mt-8 ml-2 mb-3"
            style="color: black"
            v-if="fqfSpendings.length > 0"
          >
            Periodical Bills
            <v-icon class="ml-3" v-if="!areFqfSpendingsHidden">mdi-eye</v-icon>
            <v-icon class="ml-3" v-if="areFqfSpendingsHidden"
              >mdi-eye-off</v-icon
            >
          </h3>
        </span>
      </a>

      <div v-if="!areFqfSpendingsHidden">
        <ImSpendingsCounterpartsCard
          v-for="spending in fqfSpendings"
          :key="spending.name"
          :counterpartName="spending.counterpartMonths.name"
          :counterpartMultipointIdentifier="
            spending.counterpartMultipointIdentifier
          "
          :counterpartSum="spending.counterpartMonths.sum"
          :repetition="spending.repetitionName"
          :repetitionName="spending.repetitionName"
          :categoryName="spending.categoryName"
          :january="spending.counterpartMonths.jan"
          :february="spending.counterpartMonths.feb"
          :march="spending.counterpartMonths.mar"
          :april="spending.counterpartMonths.apr"
          :mai="spending.counterpartMonths.mai"
          :june="spending.counterpartMonths.jun"
          :july="spending.counterpartMonths.jul"
          :august="spending.counterpartMonths.aug"
          :september="spending.counterpartMonths.sep"
          :oktober="spending.counterpartMonths.okt"
          :november="spending.counterpartMonths.nov"
          :december="spending.counterpartMonths.dec"
          :januaryTXs="spending.counterpartMonths.janTXs"
          :februaryTXs="spending.counterpartMonths.febTXs"
          :marchTXs="spending.counterpartMonths.marTXs"
          :aprilTXs="spending.counterpartMonths.aprTXs"
          :maiTXs="spending.counterpartMonths.maiTXs"
          :juneTXs="spending.counterpartMonths.junTXs"
          :julyTXs="spending.counterpartMonths.julTXs"
          :augustTXs="spending.counterpartMonths.augTXs"
          :septemberTXs="spending.counterpartMonths.sepTXs"
          :oktoberTXs="spending.counterpartMonths.oktTXs"
          :novemberTXs="spending.counterpartMonths.novTXs"
          :decemberTXs="spending.counterpartMonths.decTXs"
        />
      </div>

      <!-- FREQUENT VAR -->

      <a v-on:click="areFqvSpendingsHidden = !areFqvSpendingsHidden">
        <span>
          <h3
            class="mt-8 ml-2 mb-3"
            style="color: black"
            v-if="fqvSpendings.length > 0"
          >
            Regular Purchases
            <v-icon class="ml-3" v-if="!areFqvSpendingsHidden">mdi-eye</v-icon>
            <v-icon class="ml-3" v-if="areFqvSpendingsHidden"
              >mdi-eye-off</v-icon
            >
          </h3>
        </span>
      </a>

      <div v-if="!areFqvSpendingsHidden">
        <ImSpendingsCounterpartsCard
          v-for="spending in fqvSpendings"
          :key="spending.name"
          :counterpartName="spending.counterpartMonths.name"
          :counterpartMultipointIdentifier="
            spending.counterpartMultipointIdentifier
          "
          :counterpartSum="spending.counterpartMonths.sum"
          :repetition="spending.repetitionName"
          :repetitionName="spending.repetitionName"
          :categoryName="spending.categoryName"
          :january="spending.counterpartMonths.jan"
          :february="spending.counterpartMonths.feb"
          :march="spending.counterpartMonths.mar"
          :april="spending.counterpartMonths.apr"
          :mai="spending.counterpartMonths.mai"
          :june="spending.counterpartMonths.jun"
          :july="spending.counterpartMonths.jul"
          :august="spending.counterpartMonths.aug"
          :september="spending.counterpartMonths.sep"
          :oktober="spending.counterpartMonths.okt"
          :november="spending.counterpartMonths.nov"
          :december="spending.counterpartMonths.dec"
          :januaryTXs="spending.counterpartMonths.janTXs"
          :februaryTXs="spending.counterpartMonths.febTXs"
          :marchTXs="spending.counterpartMonths.marTXs"
          :aprilTXs="spending.counterpartMonths.aprTXs"
          :maiTXs="spending.counterpartMonths.maiTXs"
          :juneTXs="spending.counterpartMonths.junTXs"
          :julyTXs="spending.counterpartMonths.julTXs"
          :augustTXs="spending.counterpartMonths.augTXs"
          :septemberTXs="spending.counterpartMonths.sepTXs"
          :oktoberTXs="spending.counterpartMonths.oktTXs"
          :novemberTXs="spending.counterpartMonths.novTXs"
          :decemberTXs="spending.counterpartMonths.decTXs"
        />
      </div>

      <!-- VAR -->

      <a v-on:click="areVarSpendingsHidden = !areVarSpendingsHidden">
        <span>
          <h3
            class="mt-8 ml-2 mb-3"
            style="color: black"
            v-if="varSpendings.length > 0"
          >
            Occasional Purchases
            <v-icon class="ml-3" v-if="!areVarSpendingsHidden">mdi-eye</v-icon>
            <v-icon class="ml-3" v-if="areVarSpendingsHidden"
              >mdi-eye-off</v-icon
            >
          </h3>
        </span>
      </a>

      <div v-if="!areVarSpendingsHidden">
        <ImSpendingsCounterpartsCard
          v-for="spending in varSpendings"
          :key="spending.name"
          :counterpartName="spending.counterpartMonths.name"
          :counterpartMultipointIdentifier="
            spending.counterpartMultipointIdentifier
          "
          :counterpartSum="spending.counterpartMonths.sum"
          :repetition="spending.repetitionName"
          :repetitionName="spending.repetitionName"
          :categoryName="spending.categoryName"
          :january="spending.counterpartMonths.jan"
          :february="spending.counterpartMonths.feb"
          :march="spending.counterpartMonths.mar"
          :april="spending.counterpartMonths.apr"
          :mai="spending.counterpartMonths.mai"
          :june="spending.counterpartMonths.jun"
          :july="spending.counterpartMonths.jul"
          :august="spending.counterpartMonths.aug"
          :september="spending.counterpartMonths.sep"
          :oktober="spending.counterpartMonths.okt"
          :november="spending.counterpartMonths.nov"
          :december="spending.counterpartMonths.dec"
          :januaryTXs="spending.counterpartMonths.janTXs"
          :februaryTXs="spending.counterpartMonths.febTXs"
          :marchTXs="spending.counterpartMonths.marTXs"
          :aprilTXs="spending.counterpartMonths.aprTXs"
          :maiTXs="spending.counterpartMonths.maiTXs"
          :juneTXs="spending.counterpartMonths.junTXs"
          :julyTXs="spending.counterpartMonths.julTXs"
          :augustTXs="spending.counterpartMonths.augTXs"
          :septemberTXs="spending.counterpartMonths.sepTXs"
          :oktoberTXs="spending.counterpartMonths.oktTXs"
          :novemberTXs="spending.counterpartMonths.novTXs"
          :decemberTXs="spending.counterpartMonths.decTXs"
        />
      </div>
    </v-container>
  </div>
</template>

<script>
import ImSpendingsCounterpartsCard from "@/components/spending/ImSpendingsCounterpartsCard.vue";
import SpendingsService from "@/services/SpendingsService";

export default {
  components: {
    ImSpendingsCounterpartsCard
  },
  data() {
    return {
      repetitionOptionSelected: String,
      repetitionOptions: [
        "Regular Bills",
        "Periodical Bills",
        "Regular Purchases",
        "Occasional Purchases"
      ],

      fixSpendings: [],
      areFixSpendingsHidden: false,

      fqfSpendings: [],
      areFqfSpendingsHidden: false,

      fqvSpendings: [],
      areFqvSpendingsHidden: false,

      varSpendings: [],
      areVarSpendingsHidden: true
    };
  },
  created() {
    SpendingsService.getFixSpendings()
      .then(response => {
        this.fixSpendings = response.data;
        //console.log(this.categoryMonths);
      })
      .catch(error => {
        console.log("There was an error:", error.response);
      });

    SpendingsService.getFqfSpendings()
      .then(response => {
        this.fqfSpendings = response.data;
        //console.log(this.categoryMonths);
      })
      .catch(error => {
        console.log("There was an error:", error.response);
      });

    SpendingsService.getFqvSpendings()
      .then(response => {
        this.fqvSpendings = response.data;
        //console.log(this.categoryMonths);
      })
      .catch(error => {
        console.log("There was an error:", error.response);
      });

    SpendingsService.getVarSpendings()
      .then(response => {
        this.varSpendings = response.data;
        //console.log(this.categoryMonths);
      })
      .catch(error => {
        console.log("There was an error:", error.response);
      });
  },
  methods: {
    sortBy(prop) {
      this.projects.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
    },
    selectingRepetition(event) {
      console.log(event.target.value);
      this.repetitionOptionSelected = event.target.value;
    }
  }
};
</script>
