<template>
  <nav class="mb-0">
    <v-toolbar style="background: #F5F5F5; color: black;" flat>
      <v-toolbar-title class="light-green--text text-sm-h5">
        <v-btn text to="/start" color="light-green">
          <span class="font-weight-black">investo</span>
          <span class="font-weight-regular">nom</span>
        </v-btn>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn to="/upload" text color="black" style="font-size: medium;">
        <v-icon>mdi-account-circle-outline</v-icon>
        <!--v-icon left>mdi-account-circle-outline</v-icon-->
        <!--span style="text-transform: capitalize">Zoltan</span-->
      </v-btn>

      <v-btn text color="black" style="font-size: medium;" @click="logout">
        <v-icon>mdi-exit-to-app</v-icon>
        <!--v-icon left>mdi-exit-to-app</v-icon-->
        <!--span style="text-transform: capitalize">Log Out</span-->
      </v-btn>
    </v-toolbar>

    <v-tabs class="mb-10" background-color="#F5F5F5" color="#689F38" grow>
      <!--v-tab to="/importbank">
        <v-icon left size="30">mdi-bank-plus</v-icon>
      </v-tab-->
      <v-tab to="/upload">
        <v-icon left size="30">mdi-attachment</v-icon>
      </v-tab>
      <v-tab class="px-4 px-md-8" to="/settings">
        <v-icon left size="30">mdi-account-circle-outline</v-icon>
      </v-tab>
    </v-tabs>
  </nav>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "Home" });
      });
    }
  }
};
</script>
