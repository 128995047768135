<template>
  <v-app class="grey lighten-4">
    <LandingNavbar />

    <v-container>
      <ImRegister />
      <ImLandingFooter />
    </v-container>
  </v-app>
</template>

<script>
import LandingNavbar from "@/components/LandingNavbar";
import ImRegister from "@/components/register/ImRegister";
import ImLandingFooter from "@/components/landing/ImLandingFooter";
export default {
  components: { ImLandingFooter, ImRegister, LandingNavbar }
};
</script>
