import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

let initialAxiosInstance = axios.create({
  withCredentials: false, // This is the default
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    ApiAuthorization: "secret"
  }
});

export default new Vuex.Store({
  state: {
    user: null,
    year: null
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData;
      localStorage.setItem("user", JSON.stringify(userData));

      // Set all important header parameters for requests
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${userData.token}`;
      axios.defaults.headers.common["uuid"] = `${userData.uuid}`;
      axios.defaults.headers.common["Authorization"] = "secret";
    },
    CLEAR_USER_DATA() {
      localStorage.removeItem("user");
      localStorage.removeItem("year");
      location.reload();
    },
    YEAR_SELECTED(state, year) {
      state.year = year.year;
      localStorage.setItem("year", year.year);
      axios.defaults.headers.common["year"] = `${state.year}`;
      location.reload();
    },
    YEAR_LINK_SELECTED(state, year) {
      state.year = year.year;
      localStorage.setItem("year", year.year);
      axios.defaults.headers.common["year"] = `${state.year}`;
    },
    YEAR_REFRESHED(state, year) {
      state.year = year;
      localStorage.setItem("year", year);
      axios.defaults.headers.common["year"] = `${state.year}`;
    }
  },
  actions: {
    register({ commit }, credentials) {
      return initialAxiosInstance
        .post("https://api.investonom.com/api/v1/users", credentials)
        .then(({ data }) => {
          commit("SET_USER_DATA", data);
        })
        .catch(function(error) {
          // TODO: do something with the error message
          console.log(error);
          this.$router.push({ name: "Register" });
        });
    },
    login({ commit }, credentials) {
      return initialAxiosInstance
        .post("https://api.investonom.com/api/v1/users/login", credentials)
        .then(({ data }) => {
          console.log(data);
          commit("SET_USER_DATA", data);
        })
        .catch(function(error) {
          // TODO: do something with the error message
          console.log(error);
          this.$router.push({ name: "Login" });
        });
    },
    logout({ commit }) {
      commit("CLEAR_USER_DATA");
    },
    selectYear({ commit }, year) {
      commit("YEAR_SELECTED", year);
    },
    selectYearLink({ commit }, year) {
      commit("YEAR_LINK_SELECTED", year);
    }
  },
  getters: {
    loggedIn(state) {
      return !!state.user;
    },
    yearSelected(state) {
      return state.year;
    }
  }
});
