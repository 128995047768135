<template>
  <v-app class="grey lighten-4" style="background: #F5F5F5">
    <ImNavbar />

    <v-container fluid>
      <div class="ImUserSettings">
        <h2 class="subheading black--text ml-5 mb-10">User Settings</h2>

        <div class="mx-5">
          <v-form class="pb-10">
            <v-text-field
              label="Name"
              type="text"
              v-model="name"
              required
            ></v-text-field>

            <v-text-field
              label="Email"
              type="email"
              v-model="email"
              required
            ></v-text-field>
            <v-text-field
              label="Password"
              type="password"
              v-model="password"
              required
            ></v-text-field>
          </v-form>

          <v-btn
            type="submit"
            name="button"
            color="primary"
            class="mr-4"
            @click="updateUserSettings()"
            >Update
          </v-btn>
        </div>
      </div>

      <div class="ImUserSettingsDeleteUserData mt-10">
        <h2 class="subheading black--text ml-5 mb-10">Delete User Data</h2>

        <div class="mx-5">
          <h3>Are you sure that you want to delete your data?</h3>

          <v-btn
            type="submit"
            name="button"
            color="warning"
            class="mr-4 mt-5"
            @click="deleteUserData()"
            >Yes, Delete all my data!
          </v-btn>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import ImNavbar from "@/components/ImNavbarUserSettings";
import UserService from "@/services/UserService";

export default {
  components: { ImNavbar },
  data() {
    return {
      name: "",
      email: "",
      password: "",
      agreeToTerms: "",
      errors: null
    };
  },
  methods: {
    updateUserSettings() {
      // TODO: implement method
    },
    deleteUserData() {
      UserService.deleteUserData()
        .then(response => {
          console.log(response.data);

          // TODO: reload all 3 tables without refreshing the page
        })
        .catch(error => {
          console.log("There was an error:", error.response);
        });
    }
  }
};
</script>
