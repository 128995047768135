<template>
  <div class="ImCategories" v-if="categories.length > 0">
    <a v-on:click="areCategoriesHidden = !areCategoriesHidden">
      <span>
        <h2 class="subheading black--text ml-5">
          Spending Categories
          <v-icon class="ml-3" v-if="!areCategoriesHidden">mdi-eye</v-icon>
          <v-icon class="ml-3" v-if="areCategoriesHidden">mdi-eye-off</v-icon>
        </h2>
      </span>
    </a>

    <v-container fluid>
      <div v-if="!areCategoriesHidden">
        <ImSpendingsCategoriesCard
          v-for="categorie in categories"
          :key="categorie.name"
          :categoriesName="categorie.categoryMonths.name"
          :categoriesSum="categorie.categoryMonths.sum"
          :january="categorie.categoryMonths.jan"
          :february="categorie.categoryMonths.feb"
          :march="categorie.categoryMonths.mar"
          :april="categorie.categoryMonths.apr"
          :mai="categorie.categoryMonths.mai"
          :june="categorie.categoryMonths.jun"
          :july="categorie.categoryMonths.jul"
          :august="categorie.categoryMonths.aug"
          :september="categorie.categoryMonths.sep"
          :oktober="categorie.categoryMonths.okt"
          :november="categorie.categoryMonths.nov"
          :december="categorie.categoryMonths.dec"
          :januaryTXs="categorie.categoryMonths.janTXs"
          :februaryTXs="categorie.categoryMonths.febTXs"
          :marchTXs="categorie.categoryMonths.marTXs"
          :aprilTXs="categorie.categoryMonths.aprTXs"
          :maiTXs="categorie.categoryMonths.maiTXs"
          :juneTXs="categorie.categoryMonths.junTXs"
          :julyTXs="categorie.categoryMonths.julTXs"
          :augustTXs="categorie.categoryMonths.augTXs"
          :septemberTXs="categorie.categoryMonths.sepTXs"
          :oktoberTXs="categorie.categoryMonths.oktTXs"
          :novemberTXs="categorie.categoryMonths.novTXs"
          :decemberTXs="categorie.categoryMonths.decTXs"
        />
      </div>
    </v-container>
  </div>
</template>

<script>
import ImSpendingsCategoriesCard from "@/components/spending/ImSpendingsCategoriesCard";
import SpendingsService from "@/services/SpendingsService";

export default {
  components: {
    ImSpendingsCategoriesCard
  },
  data() {
    return {
      categories: [],
      areCategoriesHidden: false
    };
  },
  created() {
    SpendingsService.getSpendingCategories()
      .then(response => {
        this.categories = response.data;
        //console.log(this.categoryMonths);
      })
      .catch(error => {
        console.log("There was an error:", error.response);
      });
  },
  methods: {
    sortBy(prop) {
      this.projects.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
    }
  }
};
</script>
