import axios from "axios";

export default {
  getIncomeCategories() {
    return axios.get("https://api.investonom.com/api/v1/categories/incomes");
  },
  getIncomeRepetitions() {
    return axios.get("https://api.investonom.com/api/v1/repetitions/creditors");
  },
  getAllIncomes() {
    return axios.get("https://api.investonom.com/api/v1/counterparts/creditors");
  },
  getFixIncomes() {
    return axios.get("https://api.investonom.com/api/v1/counterparts/creditors/fix");
  },
  getFqfIncomes() {
    return axios.get("https://api.investonom.com/api/v1/counterparts/creditors/fqf");
  },
  getFqvIncomes() {
    return axios.get("https://api.investonom.com/api/v1/counterparts/creditors/fqv");
  },
  getVarIncomes() {
    return axios.get("https://api.investonom.com/api/v1/counterparts/creditors/var");
  },
  updateRepetitionCategory(repetitionParams) {
    return axios
      .post("https://api.investonom.com/api/v1/counterparts/changeRepetition", repetitionParams)
      .then(({ data }) => {
        console.log(data);
        location.reload();
      })
      .catch(function(error) {
        // TODO: do something with the error message
        console.log(error);
      });
  },
  updateCounterpartCategoryName(categoryParams) {
    return axios
      .post("https://api.investonom.com/api/v1/counterparts/changeCategory", categoryParams)
      .then(({ data }) => {
        console.log(data);
        location.reload();
      })
      .catch(function(error) {
        // TODO: do something with the error message
        console.log(error);
      });
  }
};
