import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Register from "../views/Register.vue";
import Login from "../views/Login.vue";
import Start from "../views/Start.vue";
import Balances from "@/views/Balances";
import Incomes from "@/views/Incomes";
import Spendings from "@/views/Spendings";
import UserSettings from "@/views/UserSettings";
import UserUpload from "@/views/UserUpload";
import Transactions from "@/views/Transactions";
import ImportBank from "@/views/ImportBank";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/start",
    name: "Start",
    component: Start,
    meta: { requiresAuth: true }
  },
  {
    path: "/balances",
    name: "Balances",
    component: Balances,
    meta: { requiresAuth: true }
  },
  {
    path: "/incomes",
    name: "Incomes",
    component: Incomes,
    meta: { requiresAuth: true }
  },
  {
    path: "/spendings",
    name: "Spendings",
    component: Spendings,
    meta: { requiresAuth: true }
  },
  {
    path: "/transactions",
    name: "Transactions",
    component: Transactions,
    meta: { requiresAuth: true }
  },
  {
    path: "/settings",
    name: "UserSettings",
    component: UserSettings,
    meta: { requiresAuth: true }
  },
  {
    path: "/upload",
    name: "UserUpload",
    component: UserUpload,
    meta: { requiresAuth: true }
  },
  {
    path: "/importbank",
    name: "ImportBank",
    component: ImportBank,
    meta: { requiresAuth: true }
  }
];

const router = new VueRouter({
  mode: "hash",
  // eslint-disable-next-line no-undef
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user");

  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next("/");
  }
  next();
});

export default router;
