<template>
  <v-flex xs12 md4 class="order-0 align-content-center">
    <v-card class="mx-2 my-2 text-center" color="grey lighten-5">
      <v-card-text>
        <v-sheet color="grey lighten-4">
          <v-sparkline
            :labels="chartLabels"
            :value="chartValues"
            color="#689F38"
            line-width="2"
            padding="16"
            stroke-linecap="round"
            smooth
          ></v-sparkline>
        </v-sheet>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="justify-center">
        <v-btn block text @click="yearLinkSelected()">
          {{ linkTitle }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  props: {
    chartLabels: Array,
    chartValues: Array,
    linkTitle: String,
    year: Number
  },
  methods: {
    yearLinkSelected() {
      this.$store
        .dispatch("selectYearLink", {
          year: this.year
        })
        .then(() => {
          this.$router.push({ name: "Incomes" });
        });
    }
  }
};
</script>
