<template>
  <v-app class="grey lighten-4" style="background: #F5F5F5">
    <ImNavbar />

    <v-container fluid>
      <ImIncomeRepetitions />
      <ImIncomeCategories />
      <ImIncomes />
    </v-container>
  </v-app>
</template>

<script>
import ImNavbar from "@/components/ImNavbar";
import ImIncomeCategories from "@/components/income/ImIncomeCategories";
import ImIncomeRepetitions from "@/components/income/ImIncomeRepetitions";
import ImIncomes from "@/components/income/ImIncomes";
export default {
  components: { ImNavbar, ImIncomeCategories, ImIncomeRepetitions, ImIncomes }
};
</script>
