<template>
  <nav class="mb-0">
    <v-toolbar style="background: #F5F5F5; color: black;" flat>
      <v-toolbar-title class="light-green--text text-sm-h5">
        <v-btn text to="/start" color="light-green">
          <span class="font-weight-black">investo</span>
          <span class="font-weight-regular">nom</span>
        </v-btn>

        <select
          v-model="yearSelected"
          style="font-size: medium; font-weight: bold;"
          @change="selectingYear($event)"
        >
          <option
            v-for="year in years"
            :key="year.year"
            :value="year.year"
            :selected="year.year === '{{ yearSelected }}'"
          >
            {{ year.year }}
          </option>
        </select>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn to="/upload" text color="black" style="font-size: medium;">
        <v-icon>mdi-account-circle-outline</v-icon>
        <!--v-icon left>mdi-account-circle-outline</v-icon-->
        <!--span style="text-transform: capitalize">Zoltan</span-->
      </v-btn>

      <v-btn text color="black" style="font-size: medium;" @click="logout">
        <v-icon>mdi-exit-to-app</v-icon>
        <!--v-icon left>mdi-exit-to-app</v-icon-->
        <!--span style="text-transform: capitalize">Log Out</span-->
      </v-btn>
    </v-toolbar>

    <v-tabs class="mb-10" background-color="#F5F5F5" color="#689F38" grow>
      <v-tab class="px-4 px-md-8" to="/balances">
        <v-icon left size="30">mdi-scale-balance</v-icon>
      </v-tab>
      <v-tab to="/incomes">
        <v-icon left size="30">mdi-cash-plus</v-icon>
      </v-tab>
      <v-tab to="/spendings">
        <v-icon left size="30">mdi-cash-minus</v-icon>
      </v-tab>
      <v-tab to="/transactions">
        <v-icon left size="30">mdi-bank-transfer</v-icon>
      </v-tab>
    </v-tabs>
  </nav>
</template>

<script>
import TransactionsService from "@/services/TransactionsService";
//import { authComputed } from "@/vuex/helpers";
export default {
  computed: {
    //...authComputed
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "Home" });
      });
    },
    selectingYear(event) {
      console.log(event.target.value);
      this.$store.dispatch("selectYear", {
        year: event.target.value
      });
    }
  },
  data() {
    return {
      yearSelected: this.$store.getters.yearSelected,
      years: []
    };
  },
  created() {
    TransactionsService.getAllTransactionYears()
      .then(response => {
        this.years = response.data;
        //console.log(this.transactions);
      })
      .catch(error => {
        console.log("There was an error:", error.response);
      });
  }
};
</script>
