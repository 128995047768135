<template>
  <v-app class="grey lighten-4">
    <LandingNavbar />

    <v-container>
      <InomLandingContent />
    </v-container>
  </v-app>
</template>

<script>
import LandingNavbar from "@/components/LandingNavbar";
import InomLandingContent from "@/components/landing/ImLandingContent";
export default {
  components: { InomLandingContent, LandingNavbar }
};
</script>
