<template>
  <v-container fluid>
    <v-layout row wrap class="pa-0 pb-2">
      <ImStartBalancesCard
        :chart-labels="balanceLabels"
        :chart-values="balanceValues"
        :link-title="balanceCardLabel"
        :year="year"
      />

      <ImStartIncomesCard
        :chart-labels="incomeLabels"
        :chart-values="incomeValues"
        :link-title="incomeCardLabel"
        :year="year"
      />

      <ImStartSpendingsCard
        :chart-labels="spendingLabels"
        :chart-values="spendingValues"
        :link-title="spendingCardLabel"
        :year="year"
      />
    </v-layout>
  </v-container>
</template>

<script>
import ImStartBalancesCard from "@/components/start/ImStartBalancesCard.vue";
import ImStartIncomesCard from "@/components/start/ImStartIncomesCard.vue";
import ImStartSpendingsCard from "@/components/start/ImStartSpendingsCard.vue";

export default {
  components: {
    ImStartBalancesCard,
    ImStartIncomesCard,
    ImStartSpendingsCard
  },
  props: {
    year: Number,
    balanceCardLabel: String,
    balanceLabels: Array,
    balanceValues: Array,

    incomeCardLabel: String,
    incomeLabels: Array,
    incomeValues: Array,

    spendingCardLabel: String,
    spendingLabels: Array,
    spendingValues: Array
  },
  data() {
    return {};
  }
};
</script>
