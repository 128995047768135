<template>
  <v-layout row text-center class="mx-2 mt-15">
    <v-flex class="mt-10">
      <v-col>
        <v-card color="grey lighten-5">
          <div class="pa-15">
            <h1 class="pb-8">Login</h1>
            <v-form class="pb-8">
              <v-text-field
                label="Email"
                type="email"
                v-model="email"
                required
              ></v-text-field>
              <v-text-field
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                v-model="password"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
              />
            </v-form>
            <v-btn type="submit" color="primary" class="mr-4" @click="login()"
              >Login</v-btn
            >
          </div>
        </v-card>
      </v-col>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "imLogin",
  data: () => ({
    email: "",
    password: "",
    showPassword: false
  }),
  methods: {
    login() {
      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password
        })
        .then(() => {
          this.$router.push({ name: "Start" });
        })
        .catch(err => {
          this.error = err.response.data.error;
        });
    }
  }
};
</script>
