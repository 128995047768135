import axios from "axios";

export default {
  deleteUserData() {
    return axios
      .delete("https://api.investonom.com/api/v1/users/data")
      .then(({ data }) => {
        console.log(data);
        location.reload();
      })
      .catch(function(error) {
        // TODO: do something with the error message
        console.log(error);
      });
  }
};
