<template>
  <v-app class="grey lighten-4" style="background: #F5F5F5">
    <ImNavbar />

    <v-container fluid>
      <ImStart />
    </v-container>
  </v-app>
</template>

<script>
import ImNavbar from "@/components/ImNavbarMinimal";
import ImStart from "@/components/start/ImStart";
export default {
  components: { ImNavbar, ImStart }
};
</script>
