<template>
  <v-flex xs12 md4 class="order-0 align-content-center">
    <v-layout
      row
      wrap
      class="pt-5 ml-1 mb-3"
      v-on:click="areBankButtonsHidden = !areBankButtonsHidden"
    >
      <span class="ml-3" style="font-size: larger; font-weight: bold;">{{
        bankName
      }}</span>
    </v-layout>

    <v-layout
      row
      wrap
      class="pt-3 ml-1 mb-2"
      v-on:click="areBankButtonsHidden = !areBankButtonsHidden"
    >
      <span
        class="ml-3"
        style="font-size: medium; font-weight: normal;"
        v-if="bankBlz"
        >BLZ: {{ bankBlz }},</span
      >
      <span class="ml-3" style="font-size: medium; font-weight: normal;"
        >BIC: {{ bankBic }}</span
      >
    </v-layout>

    <v-layout
      row
      wrap
      class="pt-3 ml-1 mb-1"
      v-on:click="areBankButtonsHidden = !areBankButtonsHidden"
    >
      <span
        class="ml-3"
        style="font-size: large; font-weight: normal;"
        v-if="bankCity"
        >{{ bankCity }},</span
      >
      <span class="ml-3" style="font-size: large; font-weight: normal;">{{
        bankLocation
      }}</span>
    </v-layout>

    <v-layout row wrap class="pt-3 ml-4 mb-1" v-if="!areBankButtonsHidden">
      <v-btn
        v-if="!isConnectBankButtonsHidden"
        type="submit"
        name="button"
        color="success"
        class="mr-4 mt-5"
        @click="connectBank()"
        >Connect Bank
      </v-btn>

      <span
        class="mt-3"
        style="font-weight: bold; color: darkred;"
        v-if="!isConnectingToBankStatusHidden && !isWebFormFinished"
        >Connecting to the bank in progress ...</span
      >

      <span
        class="mt-3"
        style="font-weight: bold; color: darkred;"
        v-if="isWebFormFinished && !isSyncFinished"
        >Synchronizing banking data in progress ...</span
      >
    </v-layout>
  </v-flex>
</template>

<script>
import BankService from "@/services/BankService";

export default {
  props: {
    bankId: Number,
    bankName: String,
    bankBic: String,
    bankBlz: String,
    bankLocation: String,
    bankCity: String,
    bankInterface: String
  },
  methods: {
    connectBank() {
      console.log("connectBank was called ...");

      BankService.connectBank(this.bankId, this.bankInterface)
        .then(response => {
          this.webFormInfo = response.data;
          console.log("webFormInfo >>>>>>>> ");
          console.log(this.webFormInfo);

          this.isConnectBankButtonsHidden = true;
          this.isConnectingToBankStatusHidden = false;

          window.open(this.webFormInfo["webFormURL"], "_blank");

          this.getWebFormStatus();
        })
        .catch(error => {
          console.log("There was an error:", error.response);
        });

      //this.getWebFormStatus();
    },
    getWebFormStatus() {
      console.log("getWebFormStatus was called ... ");

      setTimeout(() => {
        this.fetchStatusLogic();
      }, 35000);

      /*
      setTimeout(() => {
        this.fetchStatusLogic();
      }, 45000);

      setTimeout(() => {
        this.fetchStatusLogic();
      }, 60000);

      setTimeout(() => {
        this.fetchStatusLogic();
      }, 80000);

      setTimeout(() => {
        this.fetchStatusLogic();
      }, 100000);
       */
    },
    fetchStatusLogic() {
      console.log("this.isFinished -> " + this.isWebFormFinished);
      this.fetchStatusData();

      setTimeout(() => {
        this.$router.push({ name: "Start" }).catch(() => {});
      }, 40000);
    },
    syncBankingData() {
      // sync bank data -> synchronous
      this.fetchSyncData();

      // utanna meg redirect ha minden ok es kesz !!!
      this.$router.push({ name: "Start" }).catch(() => {});
    },
    fetchStatusData() {
      BankService.importStatus(this.bankId, this.bankInterface)
        .then(response => {
          this.webFormStatus = response.data;
          console.log(this.webFormStatus);

          if (
            this.webFormStatus["status"] === "COMPLETED" ||
            this.webFormStatus["status"] === "ABORTED"
          ) {
            this.isWebFormFinished = true;
            console.log("fetchStatusData -> is finished");
          }
        })
        .catch(error => {
          this.isWebFormFinished = true;
          console.log("There was an error:", error.response);
        });
    }
  },
  data() {
    return {
      webFormInfo: Object,
      areBankButtonsHidden: true,
      isConnectBankButtonsHidden: false,
      isConnectingToBankStatusHidden: true,

      webFormStatus: Object,
      isWebFormFinished: false,

      syncStatus: Object,
      isAlreadySynced: false,
      isSyncFinished: false
    };
  }
};
</script>
