<template>
  <div class="ImCategories">
    <h2 class="subheading black--text ml-5">Overview</h2>

    <h3
      class="mt-2 mt-5 ml-5 mb-3"
      style="color: black"
      v-if="startCharts.length === 0"
    >
      Please import your data here
      <v-btn to="/upload" text color="black" style="font-size: medium;">
        <v-icon>mdi-attachment</v-icon>
      </v-btn>
    </h3>

    <ImStartRow
      v-for="startChart in startCharts"
      v-bind:key="startChart.year"
      :year="startChart.year"
      :balanceCardLabel="startChart.balanceCardLabel"
      :balanceLabels="startChart.balanceLabels"
      :balanceValues="startChart.balanceValues"
      :incomeCardLabel="startChart.incomeCardLabel"
      :incomeLabels="startChart.incomeLabels"
      :incomeValues="startChart.incomeValues"
      :spendingCardLabel="startChart.spendingCardLabel"
      :spendingLabels="startChart.spendingLabels"
      :spendingValues="startChart.spendingValues"
    />
  </div>
</template>

<script>
import ImStartRow from "@/components/start/ImStartRow.vue";
import ChartService from "@/services/ChartService";

export default {
  components: {
    ImStartRow
  },
  data() {
    return {
      startCharts: []
    };
  },
  created() {
    ChartService.getStartCharts()
      .then(response => {
        this.startCharts = response.data;
        //console.log(this.startCharts);
      })
      .catch(error => {
        console.log("There was an error:", error.response);
      });
  },
  methods: {
    sortBy(prop) {
      this.projects.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
    }
  }
};
</script>

<style>
.project.complete {
  border-left: 4px solid #3cd1c2;
}
.project.ongoing {
  border-left: 4px solid #ffaa2c;
}
.project.overdue {
  border-left: 4px solid #f83e70;
}
.v-chip.complete {
  background: #3cd1c2;
}
.v-chip.ongoing {
  background: #ffaa2c;
}
.v-chip.overdue {
  background: #f83e70;
}
</style>
