<template>
  <v-layout row text-center class="mx-2 mt-15">
    <v-flex class="mt-10">
      <v-col>
        <v-card color="grey lighten-5">
          <div class="pa-15">
            <h1 class="pb-8">Create a new account</h1>

            <v-form class="pb-10">
              <v-text-field
                label="Name"
                type="text"
                v-model="name"
                required
              ></v-text-field>

              <v-text-field
                label="Email"
                type="email"
                v-model="email"
                required
              ></v-text-field>
              <v-text-field
                label="Password"
                type="password"
                v-model="password"
                required
              ></v-text-field>
              <v-checkbox
                label="Agree to terms & conditions"
                v-model="agreeToTerms"
                required
              ></v-checkbox>
            </v-form>
            <v-btn
              type="submit"
              name="button"
              color="primary"
              class="mr-4"
              @click="register()"
              >Register
            </v-btn>
            Already have an account?
            <router-link to="/login">
              Login.
            </router-link>
          </div>
        </v-card>
      </v-col>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      email: "",
      password: "",
      agreeToTerms: "",
      errors: null
    };
  },
  methods: {
    register() {
      this.$store
        .dispatch("register", {
          name: this.name,
          email: this.email,
          password: this.password
        })
        .then(() => {
          this.$router.push({ name: "Start" });
        })
        .catch(err => {
          this.errors = err.response.data.errors;
        });
    }
  }
};
</script>
