<template>
  <v-app class="grey lighten-4" style="background: #F5F5F5">
    <ImNavbar />

    <v-container fluid>
      <ImTransactions />
    </v-container>
  </v-app>
</template>

<script>
import ImNavbar from "@/components/ImNavbar";
import ImTransactions from "@/components/transactions/ImTransactions";
export default {
  components: {
    ImNavbar,
    ImTransactions
  }
};
</script>
