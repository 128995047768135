import axios from "axios";

export default {
  getAllTransactions() {
    return axios.get("https://api.investonom.com/api/v1/transactions");
  },
  getAllTransactionYears() {
    return axios.get("https://api.investonom.com/api/v1/transactions/years");
  },
  getTXsWithIds(txIds) {
    return axios.get("https://api.investonom.com/api/v1/transactions/txs/" + txIds);
  }
};
