<template>
  <v-card flat class="mb-4" :style="`border-left: 4px solid yellowgreen;`">
    <a v-on:click="areMonthsHidden = !areMonthsHidden">
      <v-card-subtitle>
        <span style="color: black; font-weight: bold; font-size: larger;">
          {{ counterpartName }}</span
        >
        <!--span class="ml-3">{{ repetitionName }} </span-->
        <span class="ml-3" v-if="areMonthsHidden">{{ categoryName }} </span>
      </v-card-subtitle>
    </a>
    <v-layout row wrap class="pa-1" v-if="areMonthsHidden" />

    <v-layout row wrap class="pa-1" v-if="areMonthsHidden">
      <span class="ml-6 mb-1">{{ formatPrice(counterpartSum) }} EUR </span>
    </v-layout>

    <v-layout row wrap class="ml-1 mb-3" v-if="!areMonthsHidden">
      <span class="ml-3">{{ formatPrice(counterpartSum) }} EUR </span>

      <select
        class="ml-3"
        v-model="repetitionOptionSelected"
        style="font-size: medium; font-weight: bold;"
        @change="changeRepetitionType($event)"
      >
        <option
          v-for="repetition in repetitionOptions"
          :key="repetition"
          :value="repetition"
          :selected="repetition === '{{ repetitionOptionSelected }}'"
        >
          {{ repetition }}
        </option>
      </select>

      <input
        class="ml-3"
        type="text"
        size="40"
        v-model="categoryNameModel"
        @change="changeCategoryName($event)"
      />
    </v-layout>

    <v-layout row wrap class="mt-5 ml-1" v-if="!areMonthsHidden">
      <ImSpendingMonthColumn
        :ctitle="counterpartName"
        cname="January"
        :cvalue="january"
        :txIdList="januaryTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="counterpartName"
        cname="February"
        :cvalue="february"
        :txIdList="februaryTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="counterpartName"
        cname="March"
        :cvalue="march"
        :txIdList="marchTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="counterpartName"
        cname="April"
        :cvalue="april"
        :txIdList="aprilTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="counterpartName"
        cname="Mai"
        :cvalue="mai"
        :txIdList="maiTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="counterpartName"
        cname="June"
        :cvalue="june"
        :txIdList="juneTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="counterpartName"
        cname="July"
        :cvalue="july"
        :txIdList="julyTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="counterpartName"
        cname="August"
        :cvalue="august"
        :txIdList="augustTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="counterpartName"
        cname="September"
        :cvalue="september"
        :txIdList="septemberTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="counterpartName"
        cname="Oktober"
        :cvalue="oktober"
        :txIdList="oktoberTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="counterpartName"
        cname="November"
        :cvalue="november"
        :txIdList="novemberTXs"
      />
      <ImSpendingMonthColumn
        :ctitle="counterpartName"
        cname="December"
        :cvalue="december"
        :txIdList="decemberTXs"
      />
    </v-layout>
  </v-card>
</template>

<script>
import ImSpendingMonthColumn from "@/components/spending/ImSpendingMonthColumn.vue";
import IncomesService from "@/services/IncomesService";

export default {
  components: {
    ImSpendingMonthColumn
  },
  props: {
    counterpartName: String,
    counterpartMultipointIdentifier: String,
    counterpartSum: Number,
    repetition: String,
    repetitionName: String,
    categoryName: String,

    january: Number,
    february: Number,
    march: Number,

    april: Number,
    mai: Number,
    june: Number,

    july: Number,
    august: Number,
    september: Number,

    oktober: Number,
    november: Number,
    december: Number,

    januaryTXs: String,
    februaryTXs: String,
    marchTXs: String,

    aprilTXs: String,
    maiTXs: String,
    juneTXs: String,

    julyTXs: String,
    augustTXs: String,
    septemberTXs: String,

    oktoberTXs: String,
    novemberTXs: String,
    decemberTXs: String
  },
  created() {
    //console.log(counterpartName);
  },
  data() {
    return {
      repetitionOptionSelected: this.repetitionName,
      categoryNameModel: this.categoryName,
      repetitionOptions: [
        "Regular Incomes",
        "Periodical Incomes",
        "Regular Earnings",
        "Occasional Earnings"
      ],
      areMonthsHidden: true
    };
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    sortBy(prop) {
      this.projects.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
    },
    selectingRepetition(event) {
      this.repetitionOptionSelected = event.target.value;
    },

    changeRepetitionType(event) {
      this.repetitionOptionSelected = event.target.value;

      IncomesService.updateRepetitionCategory({
        counterpartName: this.counterpartName,
        cdType: "CRDT",
        counterpartMultipointIdentifier: this.counterpartMultipointIdentifier,
        newRepetition: event.target.value
      });
    },

    changeCategoryName(event) {
      IncomesService.updateCounterpartCategoryName({
        counterpartName: this.counterpartName,
        cdType: "CRDT",
        counterpartMultipointIdentifier: this.counterpartMultipointIdentifier,
        newCategoryName: event.target.value
      });
    }
  }
};
</script>

<style>
.project.complete {
  border-left: 4px solid #3cd1c2;
}
.project.ongoing {
  border-left: 4px solid #ffaa2c;
}
.project.overdue {
  border-left: 4px solid #f83e70;
}
.v-chip.complete {
  background: #3cd1c2;
}
.v-chip.ongoing {
  background: #ffaa2c;
}
.v-chip.overdue {
  background: #f83e70;
}
</style>
